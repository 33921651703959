<template>
  <div>
    <houses-component />
    <footer-component />
  </div>
</template>

<script>
import HousesComponent from "@/components/houses/HousesComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";

export default {
  name: "Houses",
  title: "Recovery Houses | Baja California Health Tourism",
  components: {
    HousesComponent,
    FooterComponent,
  },
};
</script>

<style scoped></style>
